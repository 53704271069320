
function App() {
  return (
    <div className="body">

        <img src="/lyalLogo.jpeg" alt="logo" className="logo" />

    </div>
  );
}

export default App;
